import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout/Layout"
import SEO from "../components/global/Seo"
import Services from "../components/home/Services"

const OfferTemplate = ({ data }) => {

  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const photoFluid = frontmatter.photo.childImageSharp.fluid

  return (
    <Layout
      breadcrumbs={[
        { title: "Oferta", url: "/oferta" },
        {
          title: frontmatter.title,
          url: frontmatter.slug,
        },
      ]}
    >
      <SEO title={frontmatter.title} />
      <Services showTitle={false} activeTab={frontmatter.slug} />
      <div className="row padding-top-xs padding-bottom paragraph-content">
        <div className="col-md-6">
          <div
            className="text-box-padding-xs-right"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
        <div className="col-md-6">
          <Img fluid={photoFluid} alt="Banner" />
        </div>
      </div>
    </Layout>
  )
}

export default OfferTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        description
        photo {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
