import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Immunity from "../../../static/images/icons/immunity.svg"

export default function Services({ showTitle = true, activeTab = false }) {
  const query = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/(offer)/" } }) {
        nodes {
          frontmatter {
            slug
            description
            title
          }
        }
      }
    }
  `)

  return (
    <section id="services" className="services padding-bottom padding-top">
      <div className="container">
        {showTitle && <h2 className="section-title">Oferta</h2>}
        <div className="row">
          {query.allMarkdownRemark.nodes.map(({ frontmatter }) => {
            return (
              <div className="col-lg-4 mobile-spacing-bottom">
                <Link
                  to={frontmatter.slug}
                  className={`single-service ${
                    activeTab === frontmatter.slug
                      ? "single-service--active"
                      : ""
                  }`}
                >
                  <div className="service-icon border-icon border-icon--xl">
                    <Immunity />
                  </div>
                  <h3 className="service-title">{frontmatter.title}</h3>
                  <p className="service-description">
                    {frontmatter.description}
                  </p>
                </Link>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
